.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
}

.pagination button {
    width: 40px;
    height: 40px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: #1adc9aa3;
    color: #101010;
    border-color: #1adc9aa3;
}

.pagination button.active {
    font-weight: 900;
    border-color: #1adc9aa3;
    background: #000000;
    color: #fff;
}